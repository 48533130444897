module.exports = {
    name: 'Burn + Build',
    logo: 'https://img.genflow.com/burn-and-build/checkout/logo.png',
    vendorUUID: '46fecfd5-76bf-4a46-8523-8425d660359c',
    metaData: {
        title: 'Burn + Build',
        description: 'Burn fat, build muscle & become unrecognisable10 years of training experience, all in one powerful app.',
        author: 'GENFLOW',
        url: 'https://www.burnandbuildapp.com/',
        image: 'https://img.genflow.com/burn-and-build/checkout/logo.png',
    },
    theme: {
        colors: {
            primary: '#191970',
            text: '#697386',
            ctaText: '#ffffff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '',
        tagManager: '',
        tapfiliate: '',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://burnandbuildapp.com/pages/terms-and-conditions',
        privacy: 'https://burnandbuildapp.com/pages/privacy-policy',
        support: 'https://support.burnandbuildapp.com/hc/en-us',
        genflow: 'https://genflow.com',
        ios: '',
        android: '',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            product: '1month',
            label: null,
        }, {
            title: '3 Months',
            product: '3month',
            label: 'Challenge Subscription'
        }, {
            title: '12 Months',
            product: '12month',
        },
    ],
};
