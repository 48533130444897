module.exports = {
    name: 'Lift with Leana',
    logo: 'https://img.genflow.com/leana/checkout/logo.png',
    vendorUUID: '001dcbaf-4985-411f-a0d5-f2c4a83a6d00',
    metaData: {
        title: 'Lift with Leana',
        description: 'Get stronger with Leana',
        author: 'GENFLOW',
        url: 'https://www.liftwithleana.com/',
        image: 'https://img.genflow.com/leana/checkout/logo.png',
    },
    theme: {
        colors: { // Note these need to be copied into $theme-colors in src/styles/global.scss
            primary: '#3473FA',
            text: '#697386',
            ctaText: '#fff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '',
        tagManager: '',
        tapfiliate: '',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://liftwithleana.com/pages/terms-and-condition',
        privacy: 'https://liftwithleana.com/pages/privacy-policy',
        support: 'https://liftwithleana.zendesk.com/hc/en-gb',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/gb/app/lift-with-leana/id1635590575',
        android: 'https://play.google.com/store/apps/details?id=com.liftwithleana.mobile',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            product: '1month',
            // coupon: '',
            // label: 'Presale',
        }, {
            title: '3 Months',
            product: '3month',
            // coupon: 'pOxu1k3W',
            // label: 'SAVE 20%',
        }, {
            title: '12 Months',
            product: '12month',
            // coupon: 'E08Huh6v',
            // label: 'SAVE 33%',
        },
    ],
    saleProducts: [
    ],
    // currencies: {
    //     available: [
    //         {
    //             code: 'GBP',
    //             icon: '🇬🇧',
    //         },
    //         {
    //             code: 'SEK',
    //             icon: '🇸🇪',
    //         },
    //         {
    //             code: 'EUR',
    //             icon: '🇪🇺',
    //         },
    //         {
    //             code: 'USD',
    //             icon: '🇺🇸',
    //         },
    //     ],
    //     default: 'EUR',
    // },
};
